'use client';

import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from '@/components/atom/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/atom/select-input';
import { Button } from '@/components/atom/button';
import { TextArea } from '@/components/atom/textarea';
import { usePathname, useSearchParams, useRouter } from 'next/navigation';
import { ImageGalleryAdd } from '../icons';
import { EventTypeOptions } from '@/constants';
import RadioButton from '@/components/atom/radio-button';
import StepTwoForm from './StepTwoForm';
import { formSchema, ShopItemFormType } from './schemas';
import ShopItemFormPreview from './ShopItemFormPreview';
import { CreateShopItemStepEnum } from '@/utils/enums';

export default function ShopItemEditor() {
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();
  const step = searchParams.get('item-step');
  const [capturedImage, setCapturedImage] = useState<string>('');
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const imgRef = React.useRef<HTMLInputElement | null>(null);
  // const [step, setStep] = useState(3);
  const form = useForm<ShopItemFormType>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<ShopItemFormType> = (data) => {
    console.log(data);
    // Here you would typically send the data to your server
    alert('Form submitted successfully!');
  };

  const handleDateChange = (value: string, name: string) => {
    console.log({ value, name });
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error('Error accessing the camera', err);
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  const captureImage = () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      canvas.getContext('2d')?.drawImage(videoRef.current, 0, 0);
      const imageDataUrl = canvas.toDataURL('image/jpeg');
      setCapturedImage(imageDataUrl);
      stopCamera();
    }
  };

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setCapturedImage(e.target?.result as string);
      reader.readAsDataURL(file);
    }
  };

  const onNextClick = () => {
    const step = searchParams.get('item-step');
    if (step === CreateShopItemStepEnum.Info) {
      router.replace(
        `${pathname}?ref=create&type=store-item&item-step=${CreateShopItemStepEnum.Lifetime}`
      );
    } else if (step === CreateShopItemStepEnum.Lifetime) {
      router.replace(
        `${pathname}?ref=create&type=store-item&item-step=${CreateShopItemStepEnum.Summary}`
      );
    }
  };

  const onBackClick = () => {
    if (step === CreateShopItemStepEnum.Summary) {
      router.replace(
        `${pathname}?ref=create&type=store-item&item-step=${CreateShopItemStepEnum.Lifetime}`
      );
    } else if (step === CreateShopItemStepEnum.Lifetime) {
      router.replace(
        `${pathname}?ref=create&type=store-item&item-step=${CreateShopItemStepEnum.Info}`
      );
    }
  };

  return (
    <div className="w-full mt-6">
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex items-center justify-between flex-col gap-6"
      >
        {step === CreateShopItemStepEnum.Info && (
          <div className="w-full space-y-4 h-full max-h-full">
            <div className="">
              <label htmlFor="communityId">Community</label>
              <Select
                onValueChange={(v) => handleDateChange(v, 'communityId')}
                value={form.watch('communityId')}
              >
                <SelectTrigger hasError={!!form.formState.errors?.communityId?.message}>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {[].map((month) => (
                      <SelectItem key={month} value={month}>
                        {month}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="">
              <label htmlFor="productCategoryId">Product category</label>
              <Select
                onValueChange={(v) => handleDateChange(v, 'productCategoryId')}
                value={form.watch('productCategoryId')}
              >
                <SelectTrigger hasError={!!form.formState.errors?.productCategoryId?.message}>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {[].map((month) => (
                      <SelectItem key={month} value={month}>
                        {month}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="">
              <label htmlFor="image">Upload media</label>

              <div className="flex flex-col gap-4">
                <label
                  htmlFor="file-upload"
                  className=" cursor-pointer h-[216px] shadow-img bg-gray-brand4relative flex items-center justify-center border-gray-300 rounded-lg"
                >
                  <input
                    type="file"
                    id="file-upload"
                    className="hidden h-full w-full absolute top-0 bottom-0 right-0 left-0"
                    accept="image/*,video/*"
                    ref={imgRef}
                    onChange={handleFileInput}
                  />

                  <ImageGalleryAdd />
                </label>
                <div className="flex items-center justify-center w-full gap-4 rounded-sm">
                  <Button
                    variant="outline"
                    className="w-full text-purple-brand bg-white"
                    onClick={() => imgRef.current?.click()}
                  >
                    Browse Gallery
                  </Button>
                  <Button
                    onClick={capturedImage ? captureImage : startCamera}
                    variant="outline"
                    className="w-full text-purple-brand bg-white shadow-xs"
                  >
                    {capturedImage ? 'Retake picture' : 'Take a picture'}
                  </Button>
                </div>
              </div>
            </div>

            <div>
              <label className="text-sm font-medium" htmlFor="eventName">
                Name of event
              </label>
              <Input
                {...form.register('eventName')}
                errorMessage={form.formState.errors.eventName?.message}
                disabled={false}
              />
            </div>

            <div className="w-full mb-4">
              <label className="text-sm font-medium" htmlFor="username">
                Description
              </label>
              <TextArea
                {...form.register('description')}
                name="description"
                id="description"
                placeholder="Write a short description of your event (Maximum number of words: 150)"
                rows={5}
                maxLength={150}
              ></TextArea>
              <p className="text-end text-xs text-gray-brand">
                {150 - (form.watch('description')?.length ?? 0)} of 150 words left
              </p>
            </div>

            <div className="w-full">
              <label htmlFor="eventType">Event type</label>
              <div className="grid grid-cols-2 gap-4">
                {EventTypeOptions.map((v) => (
                  <RadioButton
                    key={v}
                    id={v}
                    value={v}
                    label={v}
                    {...form.register('eventType')}
                    hasError={!!form.formState.errors?.eventType?.message}
                  />
                ))}
              </div>
              {form.formState.errors?.eventType?.message && (
                <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
                  {form.formState.errors?.eventType?.message}
                </p>
              )}
            </div>
          </div>
        )}

        {step === CreateShopItemStepEnum.Lifetime && <StepTwoForm form={form} />}

        {step === CreateShopItemStepEnum.Summary && <ShopItemFormPreview />}

        <div className="flex flex-col md:flex-row justify-between gap-4 items-center w-full">
          {step === CreateShopItemStepEnum.Lifetime || step === CreateShopItemStepEnum.Summary ? (
            <Button type="button" className="w-full" onClick={onBackClick} variant="outline">
              Go Back
            </Button>
          ) : null}
          {step !== CreateShopItemStepEnum.Summary ? (
            <Button className="w-full" onClick={onNextClick}>
              Next
            </Button>
          ) : (
            <Button type="submit" className="w-full">
              Create Event
            </Button>
          )}
        </div>
      </form>
    </div>
  );
}
