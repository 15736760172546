'use client';

import React from 'react';
import { Avatar } from 'src/components/avatar';
import { ShareIcon } from '../icons';
import AvatarPlaceholder from './avatar-placeholder';
import { X } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import MainModal from '../modal/main-modal';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Image from 'next/image';
import useAppStore from '@/hooks/use-app-store';
import { useMediaQuery } from '@mui/material';
import { Button } from '../atom/button';
import { useGetUsers } from '@/hooks/react-query/users/useGetUsers';
import InfiniteScrollContainer from '../InfiniteScrollContainer';
import { APP_DOMAIN } from '@/configs/global-variables';

const ShareModal = () => {
  const { closeShareModal, showShareModal, shareTitle, path } = useAppStore((state) => state);
  const { users, hasNextPage, isFetching, fetchNextPage, setSearchText } = useGetUsers({
    enabled: showShareModal,
  });
  const [recipients, setRecipients] = React.useState(new Array(5).fill(null));
  const [isCopied, setIsCopied] = React.useState(false);
  const { toast } = useToast();

  const handleChange = (user: any) => {
    const firstUserIndex = recipients.map((recipient) => recipient?.userId).indexOf(user.userId);
    if (firstUserIndex === -1) {
      const firstNullIndex = recipients.indexOf(null);
      if (firstNullIndex !== -1) {
        const replica = [...recipients];

        replica[firstNullIndex] = user;

        setRecipients(replica);
      } else {
        toast({ description: 'You can only share with 5 users at once', variant: 'destructive' });
      }
    } else {
      const replica = [...recipients];

      replica.splice(firstUserIndex, 1);
      replica.push(null);

      setRecipients(replica);
    }
  };

  const removeRecipient = (userId: string) => {
    const firstUserIndex = recipients.map((recipient) => recipient?.userId).indexOf(userId);

    if (firstUserIndex !== -1) {
      const replica = [...recipients];

      replica.splice(firstUserIndex, 1);
      replica.push(null);

      setRecipients(replica);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(`${APP_DOMAIN}${path}`)
      .then(() => {
        setIsCopied(true);
        toast({
          variant: 'success',
          description: 'Link copied',
        });
        setTimeout(() => setIsCopied(false), 2000); // Reset the copied state after 2 seconds
      })
      .catch((error) => {
        console.error('Failed to copy the link: ', error);
      });
  };

  const isChecked = (userId: string) => recipients.map((s) => s?.userId).includes(userId);

  const onShare = () => {
    if (recipients.every((value) => value === null)) {
      toast({
        variant: 'destructive',
        description: 'Please select at least one user you want to share to',
      });
    }
    toast({
      variant: 'success',
      description: 'Successfully shared to selected users!',
    });
  };
  const match = useMediaQuery('(max-width:375px)');

  return (
    <MainModal open={showShareModal} handleClose={() => closeShareModal()} fullScreen={match}>
      <div className="md:w-full max-w-[442px] mt-10 mx-auto pb-6">
        <h1 className="text-purple-brand3 font-medium text-2xl ">{shareTitle}</h1>

        <div className="flex flex-col gap-6 w-full mt-6 ">
          <div className="w-full flex items-center justify-between gap-4">
            <div className="relative w-full max-w-[224px]">
              <TextField
                disabled={!users}
                placeholder="Search.."
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Image src="/assets/fusion/Search.svg" height={20} width={20} alt="search" />
                    </InputAdornment>
                  ),
                  className: '!rounded-sm',
                }}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>

            <button
              disabled={isCopied}
              className="w-fit p-0 h-5 font-semibold text-orange-brand hover:opacity-65 text-sm flex items-center justify-end gap-2"
              onClick={handleCopyLink}
            >
              {isCopied ? (
                'Link Copied!'
              ) : (
                <>
                  <Image src="/assets/fusion/Copy.svg" height={20} width={20} alt="copy" /> Copy
                  Link
                </>
              )}
            </button>
          </div>

          <InfiniteScrollContainer
            onButtonReached={() => hasNextPage && !isFetching && fetchNextPage()}
            className="max-h-[224px] overflow-y-auto space-y-2.5 f-scroll"
          >
            {users &&
              users.map((user: any) => (
                <div
                  key={user.userId}
                  className={`w-full flex items-center justify-between py-1.5 px-3 rounded-sm ${
                    isChecked(user.userId) ? 'bg-gray-brand10' : ''
                  }`}
                >
                  <div className="flex items-center gap-1">
                    <Avatar size={36} src={user?.avatarUrl} />
                    <div className="flex flex-col justify-center">
                      <p className="font-medium text-sm text-gray-brand7">{user.fullName}</p>
                      <p className="text-xs text-gray-brand">@{user.username}</p>
                    </div>
                  </div>

                  <Checkbox
                    size="small"
                    disableRipple
                    checked={isChecked(user.userId)}
                    onChange={() => handleChange(user)}
                  />
                </div>
              ))}
          </InfiniteScrollContainer>

          <div className="w-full flex flex-col gap-3">
            <p className="text-gray-brand7 text-base">Share to</p>
            <div className="flex flex-col md:flex-row justify-center items-center md:justify-between w-full gap-10">
              <div className="w-full flex-1 min-w-[264px] py-4 flex items-center gap-4 overflow-x-auto bg-white">
                {recipients.length === 5 &&
                  recipients.map((usr, idx) =>
                    usr ? (
                      <div className="relative" key={usr.username}>
                        <Avatar size={36} src={usr.avatarUrl} />
                        <button
                          onClick={() => removeRecipient(usr.userId)}
                          className="text-[10px] z-50 shadow-lifted rounded-full bg-white p-[2px] flex items-center justify-center absolute -bottom-1 right-0  hover:opacity-85"
                        >
                          <span className=" bg-gray-brand5 rounded-full p-[1px]">
                            <X className="text-[#2B252C]" size={10} />
                          </span>
                        </button>
                      </div>
                    ) : (
                      <AvatarPlaceholder key={idx} />
                    )
                  )}
              </div>
              <div className="w-full">
                <Button
                  onClick={onShare}
                  disabled={recipients.every((value) => value === null)}
                  className="w-full"
                >
                  <ShareIcon color="#fff" className="mr-4" />
                  Share
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainModal>
  );
};

export default ShareModal;
