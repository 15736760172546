import * as z from 'zod';

// Define the schema for each step
export const step1Schema = z.object({
  communityId: z.string().min(1, 'Please select a community'),
  productCategoryId: z.string().min(1, 'Please select a product category'),
  eventName: z.string().min(1, 'Event name is required'),
  eventType: z.string().min(1, 'Please select event type'),
  description: z.string().optional(),
  image: z.string().nullable(),
});

export const step2Schema = z.object({
  eventDate: z.string(),
  multiple: z.boolean(),
  eventTime: z.string(),
  duration: z.string(),
  price: z.string(),
  numbersAvailable: z.string(),
});

export const step3Schema = z.object({
  occupation: z.string().min(1, 'Please select an occupation'),
  experience: z.string().min(1, 'Experience is required'),
});

// Combine all schemas
export const formSchema = step1Schema.merge(step2Schema).merge(step3Schema);

export type ShopItemFormType = z.infer<typeof formSchema>;
