import { Input } from '@/components/atom/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/atom/select-input';
import { CommunityTypeOptions } from '@/constants';
import React from 'react';
import CommunityImageInput from './CommunityImageInput';
import useGetCommunityCategories from '@/hooks/react-query/communities/queries/useGetCommunityCategories';
import useGetCommunityInterests from '@/hooks/react-query/communities/queries/useGetCommunityInterests';
import RadioButton from '@/components/atom/radio-button';
import { usePathname, useRouter } from 'next/navigation';
import { useToast } from '@/hooks/use-toast';
import useTipTapEditor from '@/hooks/use-tiptap-editor';
import { EditorContent } from '@tiptap/react';
import useCreateCommunityMutation from '@/hooks/react-query/communities/mutations/useCreateCommunityMutation';
import { CreateCommunityPayloadType } from '@/types';
import { Button } from '@/components/atom/button';
import { Loader2Icon } from 'lucide-react';
import { Controller, useForm } from 'react-hook-form';
import { CommunityFormModel } from '@/models/community.model';
import { communityValidationSchema } from '@/lib/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import useCommunityStore from '@/hooks/store/use-community-store';
import { useQueryClient } from '@tanstack/react-query';

const CreateCommunityForm = () => {
  const { toast } = useToast();
  const router = useRouter();
  const pathname = usePathname();
  const queryClient = useQueryClient();
  const [img, setImg] = React.useState('');
  const { setCommunitySuccess } = useCommunityStore();
  const [file, setFile] = React.useState<File | undefined | null>();
  const { data: categoryOptions } = useGetCommunityCategories({ Label: '' });
  const { data: interestOptions } = useGetCommunityInterests({ Search: '' });
  const { control, handleSubmit, formState } = useForm<CommunityFormModel>({
    resolver: zodResolver(communityValidationSchema),
    mode: 'onChange',
  });

  const { editor, value } = useTipTapEditor({
    placeholder: 'Write a short description of your community (Maximum number of words: 100)',
    className: '!min-h-[152px]',
  });

  const createCommunityMutation = useCreateCommunityMutation();

  const onSubmit = (formValues: CommunityFormModel) => {
    if (file && img) {
      const communityTypeId = formValues.type === 'Public' ? 1 : 2;
      const base64 = img.split(',')[1];
      const payload: CreateCommunityPayloadType = {
        categoryId: Number(formValues.category),
        description: value,
        image: {
          base64,
          contentType: file?.type ?? '',
          name: file?.name ?? '',
        },
        name: formValues.name,
        handle: `@${formValues.name}`,
        interestId: Number(formValues.interest),
        communityTypeId,
      };

      createCommunityMutation.mutate(payload, {
        onSuccess: (res) => {
          if (res.success) {
            router.replace(pathname);
            setCommunitySuccess({ base64: img, name: formValues.name, ...res.data });
            queryClient.invalidateQueries();
          } else {
            toast({
              variant: 'destructive',
              description: res.message ?? 'Something went wrong while trying to create community',
            });
          }
        },
      });
    } else {
      toast({ variant: 'destructive', description: 'Please select an image or take a picture' });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="mb-4">
        <CommunityImageInput
          onImageCaptured={(v, f) => {
            setImg(v);
            setFile(f);
          }}
          disabled={createCommunityMutation.isPending}
        />
      </div>
      <div className="w-full mb-4">
        <label className="text-sm font-medium" htmlFor="name">
          Name of community
        </label>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              errorMessage={formState.errors.name?.message}
              placeholder="Enter the community name"
              disabled={createCommunityMutation.isPending}
            />
          )}
        />
      </div>

      <div className="w-full mb-4">
        <label className="text-sm font-medium" htmlFor="category">
          Category
        </label>
        <Controller
          name="category"
          control={control}
          render={({ field }) => (
            <Select
              onValueChange={field.onChange}
              value={field.value}
              disabled={createCommunityMutation.isPending}
            >
              <SelectTrigger hasError={!!formState.errors.category?.message}>
                <SelectValue placeholder="Select a category for your community" />
              </SelectTrigger>
              <SelectContent>
                {categoryOptions?.data?.map((cat: { id: string; name: string }) => (
                  <SelectItem value={`${cat.id}`}>{cat.name}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        />
        {formState.errors?.category?.message && (
          <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
            {formState.errors?.category?.message}
          </p>
        )}
      </div>
      <div className="w-full mb-4">
        <label className="text-sm font-medium" htmlFor="interest">
          Interest
        </label>
        <Controller
          name="interest"
          control={control}
          render={({ field }) => (
            <Select
              onValueChange={field.onChange}
              value={field.value}
              disabled={createCommunityMutation.isPending}
            >
              <SelectTrigger hasError={!!formState.errors.interest?.message}>
                <SelectValue placeholder="Select a interest for your community" />
              </SelectTrigger>
              <SelectContent>
                {interestOptions?.data?.map((interest: { id: string; name: string }) => (
                  <SelectItem value={`${interest.id}`}>{interest.name}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        />
        {formState.errors?.interest?.message && (
          <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
            {formState.errors?.interest?.message}
          </p>
        )}
      </div>
      <div className="w-full mb-4">
        <label className="text-sm font-medium" htmlFor="description">
          Description
        </label>
        <EditorContent editor={editor} disabled={createCommunityMutation.isPending} />
        <p className="text-end text-xs text-gray-brand">
          {150 - value?.trim()?.length} of 150 words left
        </p>
      </div>
      <div className="w-full mb-4">
        <label htmlFor="type">Community type</label>
        <div className="grid grid-cols-2 gap-4">
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <>
                {CommunityTypeOptions.map((v) => (
                  <RadioButton
                    key={v}
                    id={v}
                    value={v}
                    label={v}
                    name={field.name}
                    onChange={field.onChange}
                    hasError={!!formState.errors?.type?.message}
                  />
                ))}
              </>
            )}
          />
        </div>
        {formState.errors?.type?.message && (
          <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
            {formState.errors?.type?.message}
          </p>
        )}
      </div>
      <div className="w-full mt-4">
        <Button className="w-full" type="submit">
          {createCommunityMutation.isPending ? (
            <>
              <Loader2Icon size={16} className="animate-spin mr-2" /> Processing...
            </>
          ) : (
            'Create'
          )}
        </Button>
      </div>
    </form>
  );
};

export default CreateCommunityForm;
