import Dialog from '@mui/material/Dialog';
import React from 'react';
import { IoCloseOutline } from 'react-icons/io5';

const MainModal = ({
  open,
  handleClose,
  children,
  fullWidth = true,
  maxWidth = 'sm',
  fullScreen,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
    >
      <div className="modal-container overflow-y-auto p-5 relative">
        <IoCloseOutline
          className="text-purple-brand absolute top-8 right-8 cursor-pointer"
          onClick={handleClose}
        />
        {children}
      </div>
    </Dialog>
  );
};

export default MainModal;
