'use client';

import React, { useState, useRef } from 'react';
import { Trash2 } from 'lucide-react';
import { Button } from '@/components/atom/button';
import { ImageGalleryAdd } from '@/components/icons';

export default function CommunityImageInput({
  onImageCaptured,
  disabled,
}: {
  onImageCaptured?: (v: string, file?: File | null) => void;
  disabled?: boolean;
}) {
  const [capturedImage, setCapturedImage] = useState<string>('');
  const videoRef = useRef<HTMLVideoElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error('Error accessing the camera', err);
    }
  };

  const captureImage = () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      canvas.getContext('2d')?.drawImage(videoRef.current, 0, 0);
      const imageDataUrl = canvas.toDataURL('image/jpeg');
      setCapturedImage(imageDataUrl);
      stopCamera();
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setCapturedImage(e.target?.result as string);
      reader.readAsDataURL(file);
    }
  };

  React.useEffect(() => {
    onImageCaptured?.(capturedImage, fileInputRef?.current?.files?.[0]);
  }, [capturedImage]);

  return (
    <div className="flex flex-col items-center">
      <div className="w-full flex items-center justify-between gap-4">
        <div className="relative">
          <div className="h-32 w-32 min-w-32 bg-gray-brand4 rounded-full overflow-hidden shadow-img">
            {capturedImage ? (
              <img src={capturedImage} alt="Captured" className="w-full h-full object-cover" />
            ) : (
              <div className="w-full h-full bg-gray-brand4 flex items-center justify-center">
                <ImageGalleryAdd />
              </div>
            )}
          </div>
          {capturedImage && (
            <button
              type="button"
              disabled={disabled}
              onClick={() => setCapturedImage('')}
              className="absolute bottom-0 right-0 p-2 bg-white rounded-full shadow-md"
            >
              <Trash2 className="w-6 h-6 text-gray-600" />
            </button>
          )}
        </div>
        <div className="space-y-4">
          <Button
            disabled={disabled}
            onClick={() => fileInputRef.current?.click()}
            className="w-full text-purple-brand shadow-xs"
            variant="outline"
            type="button"
          >
            Browse Gallery
          </Button>
          <input
            disabled={disabled}
            type="file"
            ref={fileInputRef}
            onChange={handleFileInput}
            accept="image/*"
            className="hidden"
          />
          <Button
            disabled={disabled}
            onClick={capturedImage ? captureImage : startCamera}
            variant="outline"
            className="w-full text-purple-brand shadow-xs"
            type="button"
          >
            {capturedImage ? 'Retake picture' : 'Take a picture'}
          </Button>
        </div>
      </div>
      {!capturedImage && (
        <video
          ref={videoRef}
          autoPlay
          playsInline
          className="mt-4 w-full max-w-md rounded-lg hidden"
        />
      )}
    </div>
  );
}
