import { createPost } from '@/server/actions/posts.actions';
import { CreatePostPayloadType } from '@/types';
import { useMutation } from '@tanstack/react-query';

const useCreatePostMutation = () => {
  return useMutation({
    mutationKey: ['create-post'],
    mutationFn: (payload: CreatePostPayloadType) => createPost(payload),
  });
};

export default useCreatePostMutation;
