import React from 'react';

import { cn } from '@/lib/utils';
import { Info } from 'lucide-react';

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  errorMessage?: string;
  hideErrorIcon?: boolean;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, hideErrorIcon, errorMessage, ...props }, ref) => {
    return (
      <div className="relative">
        <textarea
          className={cn(
            'flex w-full duration-500 ease-in-out rounded-sm border border-gray-brand2 bg-white px-3 py-1 text-sm transition text-black-brand file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-brand9 focus-visible:outline-none focus-visible:ring-0 focus-visible:border-purple-brand disabled:cursor-not-allowed disabled:opacity-50',
            className,
            errorMessage && 'border-opal-brand5 focus-visible:border-opal-brand5'
          )}
          ref={ref}
          {...props}
        ></textarea>
        {!hideErrorIcon && errorMessage && (
          <Info
            size={16}
            strokeWidth={1.5}
            className="text-opal-brand transition duration-500 ease-linear inset-y-2.5 end-0 me-2.5 absolute"
          />
        )}
        {errorMessage && (
          <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
            {errorMessage}
          </p>
        )}
      </div>
    );
  }
);
TextArea.displayName = 'TextArea';

export { TextArea };
