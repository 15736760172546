import { getCommunityCategories } from '@/server/actions/communities.actions';
import { useQuery } from '@tanstack/react-query';

const useGetCommunityCategories = ({
  Label,
  enabled = true,
}: {
  Label: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['GetCommunityCategories', { Label }],
    queryFn: () => getCommunityCategories({ Label }),
    enabled,
  });
};

export default useGetCommunityCategories;
