'use client';
import React from 'react';
import SignInModal from './auth/SigninModal';
import PreCreateModal from '@/molecules/PreCreate/PreCreateModal';
import CreatePostModal from '@/molecules/CreatePostModal';
import CreateCommunityModal from '@/molecules/CreateCommunityModal';
import CreateCommunitySuccessModal from '@/molecules/CreateCommunitySuccessModal';
import ShareModal from '@/components/share/share-modal';
import CreateShopItemModal from '@/components/shop/CreateShopItemModal';

const Modals = () => {
  return (
    <>
      <SignInModal />
      <PreCreateModal />
      <CreatePostModal />
      <CreateCommunityModal />
      <CreateCommunitySuccessModal />
      <ShareModal />
      <CreateShopItemModal />
    </>
  );
};

export default Modals;
