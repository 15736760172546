import Img from '@/components/atom/img';
import Avatar from '../avatar';
import { Button } from '../atom/button';

export default function ShopItemFormPreview() {
  return (
    <div>
      <div className="flex justify-between">
        <div className="mb-4 flex items-center">
          <Avatar
            size={40}
            hideOnlineStatus
            src="/placeholder.svg?height=200&width=200"
            alt="Mother's Love, Care, and Wellness"
            className="rounded-md"
          />
          <div>
            <h1 className="text-sm text-gray-brand7  font-medium">
              Mother's Love, Care, and Wellness
            </h1>
            <p className="text-xs text-gray-brand">3.2k members</p>
          </div>
        </div>

        <Button variant="ghost" size="icon">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.5 18.334H2.5C2.15833 18.334 1.875 18.0507 1.875 17.709C1.875 17.3673 2.15833 17.084 2.5 17.084H17.5C17.8417 17.084 18.125 17.3673 18.125 17.709C18.125 18.0507 17.8417 18.334 17.5 18.334Z"
              fill="#7F7186"
            />
            <path
              d="M15.8495 2.90005C14.2328 1.28338 12.6495 1.24172 10.9912 2.90005L9.98283 3.90838C9.89949 3.99172 9.86616 4.12505 9.89949 4.24172C10.5328 6.45005 12.2995 8.21672 14.5078 8.85005C14.5412 8.85838 14.5745 8.86672 14.6078 8.86672C14.6995 8.86672 14.7828 8.83338 14.8495 8.76672L15.8495 7.75838C16.6745 6.94172 17.0745 6.15005 17.0745 5.35005C17.0828 4.52505 16.6828 3.72505 15.8495 2.90005Z"
              fill="#7F7186"
            />
            <path
              d="M13.0089 9.60963C12.7673 9.49297 12.5339 9.3763 12.3089 9.24297C12.1256 9.13464 11.9506 9.01797 11.7756 8.89297C11.6339 8.8013 11.4673 8.66797 11.3089 8.53463C11.2923 8.5263 11.2339 8.4763 11.1673 8.40964C10.8923 8.1763 10.5839 7.8763 10.3089 7.54297C10.2839 7.5263 10.2423 7.46797 10.1839 7.39297C10.1006 7.29297 9.95892 7.1263 9.83392 6.93463C9.73392 6.80963 9.61726 6.6263 9.50892 6.44297C9.37559 6.21797 9.25892 5.99297 9.14226 5.75964C9.1246 5.72181 9.10752 5.68417 9.09096 5.64675C8.96798 5.36898 8.60578 5.28778 8.39098 5.50258L3.61726 10.2763C3.50892 10.3846 3.40892 10.593 3.38392 10.7346L2.93392 13.9263C2.85059 14.493 3.00892 15.0263 3.35892 15.3846C3.65892 15.6763 4.07559 15.8346 4.52559 15.8346C4.62559 15.8346 4.72559 15.8263 4.82559 15.8096L8.02559 15.3596C8.17559 15.3346 8.38392 15.2346 8.48392 15.1263L13.2517 10.3585C13.468 10.1422 13.3864 9.77103 13.105 9.65098C13.0734 9.63747 13.0414 9.62369 13.0089 9.60963Z"
              fill="#7F7186"
            />
          </svg>
        </Button>
      </div>

      <div className="mb-4">
        <Img
          src="/placeholder.svg?height=300&width=500"
          alt="Event image"
          width={500}
          height={300}
          className="w-full rounded-lg object-cover h-[216px]"
        />
      </div>

      <div className="mb-4">
        <span className="text-purple-brand7 text-xs font-medium uppercase">Events</span>
        <h2 className="text-lg font-medium text-black-brand mt-1">
          Brushes & Brews: Vermont Festival Edition
        </h2>
        <p className="text-gray-brand6 text-sm mt-2">
          Join us for a delightful sip and paint event celebrating Vermont's vibrant culture. Sip
          your favorite beverages while painting picturesque landscapes and lively festival scenes.
        </p>
        <p className="text-gray-brand6 text-sm mt-2">
          Whether you're a seasoned artist or a beginner, our guided session will help you capture
          Vermont's charm on canvas. Enjoy an evening of creativity, music, and camaraderie at
          "Vermont Festival."
        </p>
      </div>

      <div className="border-t pt-4">
        <h2 className="text-lg font-medium text-black-brand">Event details</h2>
        <ul className="space-y-2 text-gray-brand6 text-sm ">
          <li className="flex items-center">
            <span className="w-5 h-5 text-purple-600 mr-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.97351 12.8935H3.69348C1.73348 12.7535 0.853516 11.2469 0.853516 9.90689C0.853516 8.56689 1.73349 7.05355 3.66016 6.92022C3.93349 6.89355 4.17348 7.10689 4.19348 7.38689C4.21348 7.66022 4.00685 7.90022 3.72685 7.92022C2.43351 8.01356 1.85352 8.98688 1.85352 9.91355C1.85352 10.8402 2.43351 11.8136 3.72685 11.9069H4.97351C5.24684 11.9069 5.47351 12.1336 5.47351 12.4069C5.47351 12.6802 5.24684 12.8935 4.97351 12.8935Z"
                  fill="#7322A9"
                />
                <path
                  d="M11.1126 12.894C11.0993 12.894 11.0926 12.894 11.0793 12.894C10.806 12.894 10.5526 12.6673 10.5526 12.394C10.5526 12.1073 10.766 11.894 11.046 11.894C11.866 11.894 12.5993 11.6073 13.1726 11.094C14.2126 10.1873 14.2793 8.88065 13.9993 7.96065C13.7193 7.04732 12.9393 6.00065 11.5793 5.83399C11.3593 5.80732 11.1859 5.64066 11.1459 5.42066C10.8793 3.82066 10.0193 2.71399 8.71263 2.31399C7.36596 1.89399 5.79261 2.30732 4.81261 3.33399C3.85927 4.32732 3.63928 5.72066 4.19261 7.25399C4.28594 7.51399 4.15264 7.80065 3.89264 7.89398C3.63264 7.98732 3.34596 7.85399 3.25263 7.59399C2.5793 5.71399 2.88597 3.91399 4.09263 2.64732C5.32597 1.35399 7.30596 0.840648 9.00597 1.36065C10.566 1.84065 11.6659 3.12732 12.0659 4.91398C13.4259 5.22065 14.5193 6.25399 14.9526 7.68066C15.426 9.23399 14.9993 10.834 13.8326 11.8473C13.0926 12.514 12.1259 12.894 11.1126 12.894Z"
                  fill="#7322A9"
                />
                <path
                  d="M7.99935 14.8542C6.65935 14.8542 5.40603 14.1408 4.71936 12.9875C4.64603 12.8742 4.5727 12.7408 4.5127 12.5942C4.28603 12.1208 4.16602 11.5808 4.16602 11.0208C4.16602 8.9075 5.88602 7.1875 7.99935 7.1875C10.1127 7.1875 11.8327 8.9075 11.8327 11.0208C11.8327 11.5875 11.7127 12.1208 11.4727 12.6142C11.4194 12.7408 11.346 12.8742 11.266 13.0008C10.5927 14.1408 9.33935 14.8542 7.99935 14.8542ZM7.99935 8.1875C6.43935 8.1875 5.16602 9.46083 5.16602 11.0208C5.16602 11.4342 5.25269 11.8208 5.41935 12.1742C5.47269 12.2875 5.51934 12.3808 5.57267 12.4675C6.07934 13.3275 7.00601 13.8542 7.99268 13.8542C8.97934 13.8542 9.90601 13.3275 10.406 12.4808C10.466 12.3808 10.5194 12.2875 10.5594 12.1942C10.7394 11.8275 10.826 11.4408 10.826 11.0275C10.8327 9.46083 9.55935 8.1875 7.99935 8.1875Z"
                  fill="#7322A9"
                />
                <path
                  d="M7.61928 12.1792C7.49262 12.1792 7.36597 12.1326 7.26597 12.0326L6.60594 11.3726C6.4126 11.1792 6.4126 10.8592 6.60594 10.6659C6.79927 10.4726 7.11927 10.4726 7.3126 10.6659L7.63263 10.9859L8.69928 9.99924C8.90595 9.81258 9.21928 9.82591 9.40595 10.0259C9.59261 10.2259 9.57929 10.5459 9.37929 10.7326L7.95929 12.0459C7.85929 12.1326 7.73928 12.1792 7.61928 12.1792Z"
                  fill="#7322A9"
                />
              </svg>
            </span>
            <span>Virtual Event</span>
          </li>
          <li className="flex items-center">
            <span className="w-5 h-5 text-purple-600 mr-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.334 13.8327H4.66732C1.72732 13.8327 0.833984 12.9393 0.833984 9.99935V9.66602C0.833984 9.39268 1.06065 9.16602 1.33398 9.16602C1.97398 9.16602 2.50065 8.63935 2.50065 7.99935C2.50065 7.35935 1.97398 6.83268 1.33398 6.83268C1.06065 6.83268 0.833984 6.60602 0.833984 6.33268V5.99935C0.833984 3.05935 1.72732 2.16602 4.66732 2.16602H11.334C14.274 2.16602 15.1673 3.05935 15.1673 5.99935V6.66602C15.1673 6.93935 14.9407 7.16602 14.6673 7.16602C14.0273 7.16602 13.5007 7.69268 13.5007 8.33268C13.5007 8.97268 14.0273 9.49935 14.6673 9.49935C14.9407 9.49935 15.1673 9.72602 15.1673 9.99935C15.1673 12.9393 14.274 13.8327 11.334 13.8327ZM1.83398 10.106C1.84732 12.3993 2.32065 12.8327 4.66732 12.8327H11.334C13.5607 12.8327 14.1007 12.4393 14.1607 10.4393C13.2073 10.2127 12.5007 9.35268 12.5007 8.33268C12.5007 7.31268 13.214 6.45268 14.1673 6.22602V5.99935C14.1673 3.61935 13.7207 3.16602 11.334 3.16602H4.66732C2.32065 3.16602 1.84732 3.59935 1.83398 5.89268C2.78732 6.11935 3.50065 6.97935 3.50065 7.99935C3.50065 9.01935 2.78732 9.87935 1.83398 10.106Z"
                  fill="#7322A9"
                />
                <path
                  d="M6.66602 4.83268C6.39268 4.83268 6.16602 4.60602 6.16602 4.33268V2.66602C6.16602 2.39268 6.39268 2.16602 6.66602 2.16602C6.93935 2.16602 7.16602 2.39268 7.16602 2.66602V4.33268C7.16602 4.60602 6.93935 4.83268 6.66602 4.83268Z"
                  fill="#7322A9"
                />
                <path
                  d="M6.66602 9.7201C6.39268 9.7201 6.16602 9.49344 6.16602 9.2201V6.77344C6.16602 6.5001 6.39268 6.27344 6.66602 6.27344C6.93935 6.27344 7.16602 6.5001 7.16602 6.77344V9.2201C7.16602 9.5001 6.93935 9.7201 6.66602 9.7201Z"
                  fill="#7322A9"
                />
                <path
                  d="M6.66602 13.8327C6.39268 13.8327 6.16602 13.606 6.16602 13.3327V11.666C6.16602 11.3927 6.39268 11.166 6.66602 11.166C6.93935 11.166 7.16602 11.3927 7.16602 11.666V13.3327C7.16602 13.606 6.93935 13.8327 6.66602 13.8327Z"
                  fill="#7322A9"
                />
              </svg>
            </span>
            <span>500 tickets</span>
          </li>
          <li className="flex items-center">
            <span className="w-5 h-5 text-purple-600 mr-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.33398 3.83398C5.06065 3.83398 4.83398 3.60732 4.83398 3.33398V1.33398C4.83398 1.06065 5.06065 0.833984 5.33398 0.833984C5.60732 0.833984 5.83398 1.06065 5.83398 1.33398V3.33398C5.83398 3.60732 5.60732 3.83398 5.33398 3.83398Z"
                  fill="#7322A9"
                />
                <path
                  d="M10.666 3.83398C10.3927 3.83398 10.166 3.60732 10.166 3.33398V1.33398C10.166 1.06065 10.3927 0.833984 10.666 0.833984C10.9393 0.833984 11.166 1.06065 11.166 1.33398V3.33398C11.166 3.60732 10.9393 3.83398 10.666 3.83398Z"
                  fill="#7322A9"
                />
                <path
                  d="M10 15.1673H6C2.25333 15.1673 1.5 13.4007 1.5 10.5473V6.43398C1.5 3.27398 2.56667 1.98732 5.30667 1.83398H10.6667C10.6733 1.83398 10.6867 1.83398 10.6933 1.83398C13.4333 1.98732 14.5 3.27398 14.5 6.43398V10.5473C14.5 13.4007 13.7467 15.1673 10 15.1673ZM5.33333 2.83398C3.46667 2.94065 2.5 3.52732 2.5 6.43398V10.5473C2.5 13.1007 2.98667 14.1673 6 14.1673H10C13.0133 14.1673 13.5 13.1007 13.5 10.5473V6.43398C13.5 3.53398 12.54 2.94065 10.6533 2.83398H5.33333Z"
                  fill="#7322A9"
                />
                <path
                  d="M13.8327 12.2324H2.16602C1.89268 12.2324 1.66602 12.0058 1.66602 11.7324C1.66602 11.4591 1.89268 11.2324 2.16602 11.2324H13.8327C14.106 11.2324 14.3327 11.4591 14.3327 11.7324C14.3327 12.0058 14.106 12.2324 13.8327 12.2324Z"
                  fill="#7322A9"
                />
                <path
                  d="M8.00065 5.5C7.18065 5.5 6.48732 5.94667 6.48732 6.81333C6.48732 7.22667 6.68065 7.54 6.97398 7.74C6.56732 7.98 6.33398 8.36667 6.33398 8.82C6.33398 9.64667 6.96732 10.16 8.00065 10.16C9.02732 10.16 9.66732 9.64667 9.66732 8.82C9.66732 8.36667 9.43398 7.97333 9.02065 7.74C9.32065 7.53333 9.50732 7.22667 9.50732 6.81333C9.50732 5.94667 8.82065 5.5 8.00065 5.5ZM8.00065 7.39333C7.65398 7.39333 7.40065 7.18667 7.40065 6.86C7.40065 6.52667 7.65398 6.33333 8.00065 6.33333C8.34732 6.33333 8.60065 6.52667 8.60065 6.86C8.60065 7.18667 8.34732 7.39333 8.00065 7.39333ZM8.00065 9.33333C7.56065 9.33333 7.24065 9.11333 7.24065 8.71333C7.24065 8.31333 7.56065 8.1 8.00065 8.1C8.44065 8.1 8.76065 8.32 8.76065 8.71333C8.76065 9.11333 8.44065 9.33333 8.00065 9.33333Z"
                  fill="#7322A9"
                />
              </svg>
            </span>
            <span>12th Dec, 2024 · 12:00PM - 5:30PM WAT</span>
          </li>
          <li className="flex items-center">
            <span className="w-5 h-5 text-purple-600 mr-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.04673 14.6525C7.10673 14.6525 6.16006 14.2925 5.44673 13.5791L2.42673 10.5591C1.69339 9.82581 1.30673 8.81247 1.35339 7.77914L1.51339 4.44581C1.58673 2.85247 2.84673 1.59247 4.44673 1.51247L7.78006 1.35247C8.81339 1.31247 9.82673 1.69247 10.5601 2.42581L13.5801 5.44581C15.0134 6.87914 15.0134 9.21914 13.5801 10.6525L10.6534 13.5791C9.93339 14.2925 8.99339 14.6525 8.04673 14.6525ZM3.13339 9.84581L6.15339 12.8658C6.66006 13.3725 7.33339 13.6525 8.04673 13.6525C8.76006 13.6525 9.43339 13.3725 9.94006 12.8658L12.8667 9.93914C13.3734 9.43247 13.6534 8.75914 13.6534 8.04581C13.6534 7.33247 13.3734 6.65914 12.8667 6.15247L9.84673 3.13247C9.31339 2.59914 8.57339 2.31247 7.82673 2.35247L4.49339 2.51247C3.40673 2.55914 2.56006 3.40581 2.50673 4.48581L2.34673 7.81914C2.31339 8.57247 2.60006 9.31247 3.13339 9.84581Z"
                  fill="#7322A9"
                />
                <path
                  d="M6.33268 8.49935C5.13935 8.49935 4.16602 7.52602 4.16602 6.33268C4.16602 5.13935 5.13935 4.16602 6.33268 4.16602C7.52602 4.16602 8.49935 5.13935 8.49935 6.33268C8.49935 7.52602 7.52602 8.49935 6.33268 8.49935ZM6.33268 5.16602C5.69268 5.16602 5.16602 5.69268 5.16602 6.33268C5.16602 6.97268 5.69268 7.49935 6.33268 7.49935C6.97268 7.49935 7.49935 6.97268 7.49935 6.33268C7.49935 5.69268 6.97268 5.16602 6.33268 5.16602Z"
                  fill="#7322A9"
                />
              </svg>
            </span>
            <span>₦ 2,000</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
