import { ImageGalleryAdd, LinearLayer, ThrashIcon } from '@/components/icons';
import { cn } from '@/lib/utils';
import React, { useState } from 'react';

interface Media {
  name: string;
  contentType: string;
  base64: string;
}

export interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onImageSelected: (v: Media[], selectedFiles: File[]) => void;
}

const MultipleImageUploader = React.forwardRef<HTMLInputElement, FileInputProps>(
  ({ onImageSelected, className, onChange, ...props }, ref) => {
    const [images, setImages] = useState<Media[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files) {
        const fileArray = Array.from(files);
        setSelectedFiles(fileArray);
        const newImages = fileArray.map((file) => convertToBase64(file));
        Promise.all(newImages).then((base64Images) => {
          setImages((prevImages) => [...prevImages, ...base64Images].slice(0, 4));
        });
      }
    };

    const convertToBase64 = (file: File): Promise<Media> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            resolve({
              name: file.name,
              contentType: file.type,
              base64: reader.result.toString(),
            });
          } else {
            reject(new Error('Failed to convert file to base64'));
          }
        };
        reader.onerror = () => reject(new Error('Failed to read file'));
        reader.readAsDataURL(file);
      });
    };

    const handleRemoveImage = (index: number) => {
      setImages((prevImages) => {
        const newImages = prevImages.filter((_, i) => i !== index);
        // Update the file input value
        // if (ref.current) {
        //   const dt = new DataTransfer();
        //   newImages.forEach((img) => dt.items.add(img.file));
        //   ref.current.files = dt.files;
        // }
        return newImages;
      });
    };

    React.useEffect(() => {
      const imgs = images.map((v) => ({ ...v, base64: v.base64.split(',')[1] }));
      onImageSelected(imgs, selectedFiles);
    }, [images, selectedFiles]);

    return (
      <div className="p-4">
        <div className="flex md:flex-wrap gap-4 overflow-x-scroll md:overflow-x-auto">
          {images.map((image, index) => (
            <div key={index} className="relative">
              <img
                src={image.base64}
                alt={image.name}
                className="w-32 min-w-32 h-24 object-cover rounded-md"
              />
              <button
                onClick={() => handleRemoveImage(index)}
                className="absolute top-0 right-0 bg-gray-brand12/60 text-white p-1 "
              >
                <LinearLayer />
              </button>
              <button
                onClick={() => handleRemoveImage(index)}
                className="absolute bottom-0 right-0 bg-gray-brand12/60 text-white p-1 "
              >
                <ThrashIcon />
              </button>
            </div>
          ))}
          <label
            className={cn(
              'flex items-center justify-center w-24 min-w-24 h-24 upload-border border-gray-brand2 rounded-md cursor-pointer',
              images.length === 0 && 'hidden h-0'
            )}
          >
            <input
              ref={ref}
              type="file"
              className="hidden"
              onChange={(e) => {
                handleImageChange(e);
                onChange?.(e);
              }}
              multiple
              accept="image/*"
              {...props}
            />
            <ImageGalleryAdd />
          </label>
        </div>
      </div>
    );
  }
);

export default MultipleImageUploader;
