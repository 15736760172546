import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(localeData);

export const getMonths = () => dayjs.months();

export function getDaysOfMonth() {
  const daysInMonth = dayjs(new Date()).daysInMonth();
  return Array.from({ length: daysInMonth }, (_, i) => i + 1);
}

export function getYearsArray() {
  const currentYear = dayjs().year();
  const startYear = currentYear - 14;
  const endYear = currentYear - 75;

  const yearsArray = [];
  for (let year = startYear; year >= endYear; year--) {
    yearsArray.push(year);
  }

  return yearsArray;
}
