import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-lazy-load-image-component/src/effects/blur.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/atom/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Modals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/tailwind.css");
