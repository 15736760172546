import { TicketTypeOptions } from '@/constants';
import React from 'react';
import RadioButton from '@/components/atom/radio-button';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/atom/select-input';
import { getDaysOfMonth, getMonths, getYearsArray } from '@/lib/days';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ShopItemFormType } from './schemas';
import { Switch } from '@/components/atom/switch';
import { Tag } from 'lucide-react';
import { Input } from '@/components/atom/input';
import { separateThousand } from '@/lib/separateThousand';

const StepTwoForm = ({ form }: { form: UseFormReturn<ShopItemFormType> }) => {
  const [dates, setDates] = React.useState<{
    month?: string;
    day?: string;
    year?: string;
    initial?: boolean;
  }>({});

  const handleDateChange = (value: any, label: 'month' | 'day' | 'year') => {
    setDates((prev) => ({ ...prev, [label]: value }));
  };

  return (
    <>
      <div className="w-full">
        <label htmlFor="eventType">Ticket type</label>
        <div className="grid grid-cols-2 gap-4">
          {TicketTypeOptions.map((v) => (
            <RadioButton
              key={v}
              id={v}
              value={v}
              label={v}
              {...form.register('eventType')}
              hasError={!!form.formState.errors?.eventType?.message}
            />
          ))}
        </div>
        {form.formState.errors?.eventType?.message && (
          <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
            {form.formState.errors?.eventType?.message}
          </p>
        )}
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="price" className="text-base font-normal">
            Price
          </label>
          <div className="relative">
            <span className="absolute text-black-brand text-sm left-3 top-1/2 -translate-y-1/2 z-10">
              ₦
            </span>
            <Controller
              name="price"
              control={form.control}
              render={({ field }) => (
                <Input
                  {...field}
                  value={separateThousand(field.value)}
                  onChange={(e) => field.onChange(separateThousand(e.target.value))}
                  className="pl-7 pr-8"
                />
              )}
            />
            <Tag className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400" size={18} />
          </div>
        </div>
        <div>
          <label htmlFor="numbersAvailable" className="text-base font-normal">
            Numbers available
          </label>
          <Controller
            name="numbersAvailable"
            control={form.control}
            render={({ field }) => (
              <Input
                {...field}
                value={separateThousand(field.value)}
                onChange={(e) => field.onChange(separateThousand(e.target.value))}
                className="mt-1.5"
              />
            )}
          />
        </div>
      </div>
      <div className="w-full flex items-center justify-end gap-2 flex-row-reverse">
        <label htmlFor="eventType">Multiple days</label>
        <Switch
          checked={form.watch('multiple')}
          onCheckedChange={(v: boolean) => form.setValue('multiple', v)}
          aria-readonly
        />
      </div>

      <div className="h-[1px] w-full bg-gray-brand10" />

      <div className="w-full">
        <label htmlFor="eventDate">Date</label>

        <div className="grid grid-cols-7 gap-4">
          <div className="col-span-3">
            <Select onValueChange={(v) => handleDateChange(v, 'month')} value={dates.month}>
              <SelectTrigger hasError={!!form.formState.errors?.eventDate?.message}>
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="h-[200px]">
                <SelectGroup>
                  {getMonths().map((month) => (
                    <SelectItem key={month} value={month}>
                      {month}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div className="col-span-2">
            <Select onValueChange={(v) => handleDateChange(v, 'day')} value={dates.day}>
              <SelectTrigger hasError={!!form.formState.errors?.eventDate?.message}>
                <SelectValue placeholder="" />
              </SelectTrigger>
              <SelectContent className="h-[200px]">
                <SelectGroup>
                  {getDaysOfMonth().map((day: number) => (
                    <SelectItem key={day} value={`${day}`}>
                      {day}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>

          <div className="col-span-2">
            <Select onValueChange={(v) => handleDateChange(v, 'year')} value={dates.year}>
              <SelectTrigger hasError={!!form.formState.errors?.eventDate?.message}>
                <SelectValue placeholder="" />
              </SelectTrigger>
              <SelectContent className="h-[200px]">
                <SelectGroup>
                  {getYearsArray().map((year: number) => (
                    <SelectItem key={year} value={`${year}`}>
                      {year}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>

        {form.formState.errors?.eventDate?.message && (
          <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
            {form.formState.errors?.eventDate?.message}
          </p>
        )}
      </div>

      <div className="flex items-center gap-4 w-full">
        <div className="flex-grow">
          <label htmlFor="eventTime" className="text-base font-normal">
            Time
          </label>
          <Controller
            name="eventTime"
            control={form.control}
            render={({ field }) => (
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <SelectTrigger className="mt-1.5">
                  <SelectValue placeholder="Select time" />
                </SelectTrigger>
                <SelectContent className="h-[200px]">
                  {Array.from({ length: 24 }, (_, i) => (
                    <SelectItem key={i} value={`${i % 12 || 12}:00 ${i < 12 ? 'AM' : 'PM'}`}>
                      {`${i % 12 || 12}:00 ${i < 12 ? 'AM' : 'PM'}`}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          />
        </div>
        <div className="flex-grow">
          <label htmlFor="duration" className="text-base font-normal">
            Duration
          </label>
          <Controller
            name="duration"
            control={form.control}
            render={({ field }) => (
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <SelectTrigger className="mt-1.5">
                  <SelectValue placeholder="Select duration" />
                </SelectTrigger>
                <SelectContent className="h-[200px]">
                  {Array.from({ length: 24 * 4 }, (_, i) => {
                    const hours = Math.floor(i / 4)
                      .toString()
                      .padStart(2, '0');
                    const minutes = ['00', '15', '30', '45'][i % 4];
                    return (
                      <SelectItem key={i} value={`${hours}:${minutes}`}>
                        {`${hours}:${minutes}`}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default StepTwoForm;
