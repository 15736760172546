'use client';
import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/atom/dialog';
import { SearchInput } from '@/components/atom/search-input';
import { Button } from '@/components/atom/button';
import { FunnelIcon, SendPost } from '@/components/icons';
import Avatar from '@/components/atom/avatar';
import { cn } from '@/lib/utils';
import { ChevronLeft, Loader2Icon } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import useGetUserJoinedCommunitiesQuery from '@/hooks/react-query/communities/queries/useGetUserJoinedCommunitiesQuery';
import InfiniteScrollContainer from '@/components/InfiniteScrollContainer';
import { CommunityType } from '@/types';
import AvatarList from './avaterList';

const CommunityItem = ({
  onClick,
  active,
  data,
}: {
  active: boolean;
  onClick?: () => void;
  data: CommunityType;
}) => (
  <Button
    onClick={onClick}
    variant="ghost"
    className={cn(
      'flex w-full justify-start h-fit items-center gap-4 py-1 px-2 rounded-sm',
      active && 'shadow-img bg-pink-brand hover:bg-pink-brand'
    )}
  >
    <Avatar className="h-14 w-14" src={data?.imageUrl} fallbackText={data?.name} />
    <div className="space-y-1 flex flex-col">
      <p className="text-sm text-black-brand">{data?.name}</p>
      <p className="text-xs text-gray-brand5 text-justify">
        {data?.memberAvatarUrls && data.memberAvatarUrls.length > 0 && (
          <AvatarList imageUrls={data?.memberAvatarUrls?.slice(0, 4) ?? []} />
        )}{' '}
        {data?.membersCount} members
      </p>
    </div>
  </Button>
);

const SelectCommunityModal = ({
  open,
  onOpenChange,
  onFinishPost,
  loading,
}: {
  open: boolean;
  onFinishPost: (id: number) => void;
  onOpenChange?: () => void;
  loading: boolean;
}) => {
  const { toast } = useToast();
  const [search, setSearch] = React.useState('');
  const [selected, setSelected] = React.useState<null | number>(null);

  const { data, isFetchingNextPage, isFetching, hasNextPage, fetchNextPage } =
    useGetUserJoinedCommunitiesQuery({ Search: search });

  const handleSubmit = () => {
    if (selected) {
      onFinishPost(selected);
    } else {
      toast({ description: 'Please select a community to post to', variant: 'destructive' });
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="w-screen h-screen md:h-fit p-4 md:p-10 antialiased flex flex-col items-center  md:w-full max-w-[824px] rounded-xl">
        <DialogHeader className="w-full mx-auto text-start">
          <DialogTitle className="relative font-medium text-lg md:text-2xl text-purple-brand3 items-center flex gap-2">
            <Button
              variant="ghost"
              size="icon"
              className="rounded-full h-fit w-fit md:hidden"
              onClick={onOpenChange}
            >
              <ChevronLeft size={16} />
            </Button>
            <span className="sr-only">Select a community to post in</span>
            Select a community to post in
          </DialogTitle>
        </DialogHeader>
        <div className="flex h-full items-center flex-col  gap-8 w-full mt-6">
          <section className="flex items-center gap-4 w-full">
            <div className="w-full max-w-[312px]">
              <SearchInput placeholder="Search for community" handleChange={(v) => setSearch(v)} />
            </div>

            <Button variant="ghost" size="icon" className="rounded-full">
              <FunnelIcon />
            </Button>
          </section>

          <section className="flex flex-col justify-between md:justify-start h-full flex-1 w-full gap-4">
            <InfiniteScrollContainer
              onButtonReached={() => hasNextPage && !isFetching && fetchNextPage()}
              loading={isFetchingNextPage}
              className="max-h-[342px] pr-5 overflow-y-auto grid grid-cols-1 md:grid-cols-2 gap-4"
            >
              {data?.map((v: CommunityType) => (
                <CommunityItem
                  key={v.id}
                  data={v}
                  active={selected === v.id}
                  onClick={() => setSelected((prev) => (prev !== v.id ? v.id : null))}
                />
              ))}
            </InfiniteScrollContainer>

            <section className="flex items-center justify-end w-full">
              <Button
                disabled={!selected || loading}
                className="w-full md:w-fit"
                onClick={handleSubmit}
              >
                {loading ? (
                  <>
                    <Loader2Icon size={20} className="animate-spin" />
                    &nbsp;Please wait...
                  </>
                ) : (
                  <>
                    <SendPost />
                    &nbsp;Post
                  </>
                )}{' '}
              </Button>
            </section>
          </section>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SelectCommunityModal;
