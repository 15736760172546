'use client';
import React from 'react';
import { MotionLazy } from './animate/motion-lazy';
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import { SessionProvider } from 'next-auth/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { TooltipProvider } from './atom/tooltip';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
    },
  },
});

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <SessionProvider>
      <QueryClientProvider client={queryClient}>
        <MotionLazy>
          <ProgressBar
            height="4px"
            color="#7635dc"
            options={{ showSpinner: false }}
            shallowRouting
          />
          <TooltipProvider>{children}</TooltipProvider>
        </MotionLazy>
      </QueryClientProvider>
    </SessionProvider>
  );
};

export default Providers;
