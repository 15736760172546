'use client';

import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/atom/dialog';
import { useRouter, useSearchParams } from 'next/navigation';
import { Button } from '@/components/atom/button';
import { ChevronLeft } from 'lucide-react';
import CreateCommunityForm from './CreateCommunityForm';
import useCommunityStore from '@/hooks/store/use-community-store';

const CreateCommunityModal = () => {
  const router = useRouter();
  const params = useSearchParams();
  const { communitySuccess } = useCommunityStore();
  const opened = params.get('type') === 'community' && !communitySuccess;
  return (
    <Dialog open={opened} onOpenChange={() => router.back()}>
      <DialogContent className="w-screen h-screen md:h-fit p-0 md:p-0 antialiased flex flex-col items-center justify-between  md:w-full max-w-[440px] rounded-none md:rounded-xl">
        <DialogHeader className="w-full mx-auto text-start px-4 md:px-10 py-4">
          <DialogTitle className="relative font-medium text-2xl text-purple-brand3 flex items-center">
            <Button
              variant="ghost"
              size="icon"
              className="rounded-full h-fit w-fit md:hidden"
              onClick={() => router.back()}
            >
              <ChevronLeft size={16} />
            </Button>
            <span className="sr-only">Create a community</span>
            <span className="text-lg md:text-2xl md:block">Create a community</span>
          </DialogTitle>
          <DialogDescription>
            Create a space where you can connect with people of like mind and shared interest
          </DialogDescription>
        </DialogHeader>
        <div className="w-full h-full min-h-[200px] overflow-y-auto p-4 md:p-10">
          {opened && <CreateCommunityForm />}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateCommunityModal;
