import Img from '@/components/atom/img';
import { cn } from '@/lib/utils';
import Image from 'next/image';

// const data = [
//   {
//     name: 'Emily Davis',
//     position: 'Frontend Developer',
//     image:
//       'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=800&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
//   },
//   {
//     name: 'Michael Wilson',
//     position: 'Backend Developer',
//     image:
//       'https://images.unsplash.com/photo-1564564321837-a57b7070ac4f?q=80&w=800&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
//   },
//   {
//     name: 'Sarah Thompson',
//     position: 'Data Scientist',
//     image:
//       'https://images.unsplash.com/photo-1573496799515-eebbb63814f2?q=80&w=800&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
//   },
// ];

export default function AvatarList({
  size = 'md',
  className,
  imageUrls,
}: {
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  imageUrls: string[];
}) {
  const sizes: Record<'sm' | 'md' | 'lg', string> = {
    lg: 'm-3 size-6',
    md: 'm-2 size-12',
    sm: 'm-[2px] size-6',
  };

  return (
    <div className={cn('flex', className)}>
      {imageUrls.map((item, index) => (
        <div
          key={item}
          className={cn(
            'group relative z-0 ml-0 si flex scale-100 items-center transition-all duration-200 ease-in-out hover:z-10 hover:scale-110',
            index > 0 && '-ml-5'
          )}
        >
          <div className="relative overflow-hidden rounded-full bg-white">
            <div className="bg-size pointer-events-none absolute h-full w-full animate-bg-position from-violet-500 from-30% via-cyan-400 via-50% to-pink-500 to-80% bg-[length:300%_auto] opacity-15 group-hover:bg-gradient-to-r" />
            <div className="z-1 blur-lg" />
            <Img
              className={cn('rounded-full object-cover', sizes[size] ?? sizes.md)}
              src={item || ''}
              alt={item}
              height={500}
              width={500}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
